import withApollo from 'next-with-apollo'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { config } from '@fortawesome/fontawesome-svg-core' // required for fontawesome, see https://github.com/FortAwesome/react-fontawesome#nextjs
import '@fortawesome/fontawesome-svg-core/styles.css' // Import the CSS
import * as Sentry from '@sentry/node'
import { getCurrentUser, getToken } from '~/utils/auth'
import { appWithTranslation, i18n } from '~/i18n'

import 'react-datepicker/dist/react-datepicker.css'
import '~/styles/App.scss'
import ToastProvider from '~/contexts/ToastProvider'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NODE_ENV
  })
  Sentry.configureScope(function(scope) {
    const user = getCurrentUser()
    if (user) {
      scope.setUser({ id: user.id })
    }
  })
}

config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

const App = ({ Component, pageProps, apollo, err }) => (
  <ApolloProvider client={apollo}>
    <ToastProvider>
      <Component {...pageProps} err={err} />
    </ToastProvider>
  </ApolloProvider>
)

export const getApolloClient = ({ ctx, initialState = {} } = {}) => {
  return new ApolloClient({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    cache: new InMemoryCache().restore(initialState),
    request: (operation) => {
      const language = i18n.language || ctx.req.language
      const path = ctx?.req.url ?? window.location.pathname
      const slugOrganizationPathRegex = /^\/[A-Za-z_]{2}\/organizations\/.*/
      const userPathRegex = /^\/[A-Za-z_]{2}\/users\/.*/
      const adminPathRegex = /^\/[A-Za-z_]{2}\/admin\/.*/
      const sendToken =
        slugOrganizationPathRegex.test(path) ||
        adminPathRegex.test(path) ||
        userPathRegex.test(path)

      if (sendToken && operation.operationName !== 'GetOrganizationInfoBySlug') {
        const token = getToken(ctx)
        if (token) {
          operation.setContext({
            headers: {
              authorization: `Bearer ${token}`,
            }
          })
        }
      }
      // set current language as query variable
      operation.variables = { language, ...operation.variables }
      return operation
    }
  })
}

export default withApollo(getApolloClient)(appWithTranslation(App))
