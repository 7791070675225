import PropTypes from 'prop-types'
import { Toast as BSToast } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamationTriangle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'

import withLocale from '~/hocs/locale'
import { useToast } from '~/contexts/ToastProvider'

import styles from './index.module.scss'

const Toast = ({
  children, id, variant, successLabel, warningLabel, errorLabel
}) => {
  const { removeToast } = useToast()
  const header = {
    success: {
      title: successLabel,
      icon: faCheck,
    },
    warning: {
      title: warningLabel,
      icon: faExclamationTriangle,
    },
    danger: {
      title: errorLabel,
      icon: faTimesCircle,
    },
  }

  const onClose = () => {
    removeToast(id)
  }

  return (
    <BSToast
      show={!!id}
      className={`bg-${variant} text-white p-2 ${styles.content}`}
      autohide
      delay={3000}
      onClose={onClose}
      animation
    >
      <BSToast.Header className={`bg-${variant} border-bottom-0`}>
        <FontAwesomeIcon icon={header[variant].icon} size="lg" className="mr-2" color="white" />
        <strong className="mr-auto text-white">{header[variant].title}</strong>
      </BSToast.Header>
      <BSToast.Body className={styles.body}>{children}</BSToast.Body>
    </BSToast>
  )
}

Toast.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'danger']).isRequired,
  successLabel: PropTypes.string.isRequired,
  warningLabel: PropTypes.string.isRequired,
  errorLabel: PropTypes.string.isRequired,
}

export default withLocale('common', 'toast')(Toast)
