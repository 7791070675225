import PropTypes from 'prop-types'

import Toast from '~/components/shared/Toast'

import styles from './index.module.scss'

const ToastList = ({ toasts }) => (
  <div className={`${styles.wrapper} mt-4 mr-4`}>
    {toasts.map((item) => (
      <Toast
        key={item.id}
        id={item.id}
        variant={item.variant}
      >
        {item.message}
      </Toast>
    ))}
  </div>
)

ToastList.propTypes = {
  toasts: PropTypes.array.isRequired,
}

export default ToastList
