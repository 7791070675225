import {
  useState, useContext, useCallback, createContext
} from 'react'
import PropTypes from 'prop-types'

import ToastList from '~/components/shared/ToastList'
import { useTranslation } from '~/i18n'

const ToastContext = createContext(null)

let id = 1

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])
  const { t } = useTranslation()

  const addToast = useCallback(
    (variant, tNamespaces, tKey) => {
      setToasts(_toasts => [
        ..._toasts,
        {
          // eslint-disable-next-line no-plusplus
          id: id++,
          variant,
          // if you not pass tKey, tNamespaces behaves as a non translated message
          message: tKey
            ? t(tKey, {
              ns: tNamespaces,
            })
            : tNamespaces,
        }
      ])
    },
    [setToasts]
  )

  const removeToast = useCallback(
    _id => {
      setToasts(toasts_ => toasts_.filter(toast => toast.id !== _id))
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      <ToastList toasts={toasts} />
      {children}
    </ToastContext.Provider>
  )
}

const useToast = () => {
  const toastHelpers = useContext(ToastContext)

  return toastHelpers
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { useToast }
export default ToastProvider
